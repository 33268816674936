import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, InputAdornment, Paper } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import QuizFormTeste from '../../pages/QuizPage/quiz-form.component';
import QuestionFormTeste from '../../pages/QuestionPage/question-form-teste.component';
import { useLocation, useNavigate } from 'react-router-dom';
import IndicatorFormTest from '../../pages/IndicatorPage/indicator-form';
import PeriodForm from '../../pages/filestodelete/period-form.component';
import PostRoleFormTeste from '../../pages/PostRolePage/post-role-form';
import PeriodFormTest from '../../pages/PeriodPage/period-formtest.component';
import DimensionFormTest from '../../pages/DimensionPage/dimension-formtest';
import CreateCompany from '../../pages/CompanyPage/Company_Creation_Page/create_company';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GET_POST_ROLES } from '../../pages/DimensionPage/query.gql';
import FilterListIcon from '@mui/icons-material/FilterList';
import i18n from '../../translate/i18n';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import { theme } from '../Theme';
import ClearIcon from '@mui/icons-material/Clear';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '19ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const SearchAddBar = ({ searched, setSearched, setData, data }) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const currentLocation = useLocation()
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const isMobileMode = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumMode = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        if (currentLocation.pathname === '/collaborator') {
            navigate('/collaborator/create');
        } else {
            setOpen(true)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClear = () => {
        setSearched('');
    };

    const [indicators] = useState([]);
    const [dimensions] = useState([]);
    const [postRoles] = useState([]);
    const [evaluationPeriod] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [quizzes] = useState([]);
    const [questions] = useState([]);
    const [updaters, setUpdaters] = useState([])

    return (
        <Grid sx={{ marginBottom: 3 }}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={3}>
                    <Paper>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon color='disabled' />
                            </SearchIconWrapper>
                            <StyledInputBase
                                inputProps={{ 'aria-label': 'search' }}
                                value={searched}
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </Search>
                    </Paper>
                </Grid>
                {searched && <Grid item xs={0.2}>
                    <ClearIcon
                        sx={{
                            cursor: "pointer",
                            marginTop: "0.5rem",
                            marginLeft: "-0.55rem",
                        }}
                        onClick={handleClear}
                    />
                </Grid>}
                <Grid item xs={1.2}>
                    <Paper>
                        <Button variant="text" sx={{ marginTop: '0.1rem', color: 'grey' }} startIcon={<FilterListIcon />}>{isMobileMode ? '' : i18n.t('button.filter', { lng: selectedLanguage })}</Button>
                    </Paper>
                </Grid>
                <Grid item xs={searched ? 7.6 : 7.8} container justifyContent="flex-end">
                    <Button variant="contained" startIcon={<AddIcon />} onClick={handleClickOpen}>{i18n.t('button.new', { lng: selectedLanguage })}</Button>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
            >
                <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
                    {currentLocation.pathname === '/question' ? i18n.t('title.question', { lng: selectedLanguage }) :
                        currentLocation.pathname === '/quiz' ? i18n.t('title.quiz', { lng: selectedLanguage }) :
                            currentLocation.pathname === '/collaborator/role' ? i18n.t('title.role', { lng: selectedLanguage }) :
                                currentLocation.pathname === '/dimension' ? i18n.t('title.dimension', { lng: selectedLanguage }) :
                                    currentLocation.pathname === '/period' ? i18n.t('title.period', { lng: selectedLanguage }) :
                                        currentLocation.pathname === '/collaborator' ? i18n.t('title.collaborator', { lng: selectedLanguage }) :
                                            currentLocation.pathname === '/companies' ? i18n.t('title.company', { lng: selectedLanguage }) : i18n.t('title.indicator', { lng: selectedLanguage })}
                </DialogTitle>
                <DialogContent>

                    {currentLocation.pathname === '/question' ? <QuestionFormTeste question={questions} titleAction={i18n.t('title.question', { lng: selectedLanguage })} handleClose={handleClose} setData={setData} /> :
                        currentLocation.pathname === '/quiz' ? <QuizFormTeste quiz={quizzes} titleAction={i18n.t('title.quiz', { lng: selectedLanguage })} handleClose={handleClose} setData={setData} /> :
                            currentLocation.pathname === '/indicator' ? <IndicatorFormTest indicador={indicators} titleAction={i18n.t('title.indicator', { lng: selectedLanguage })} handleClose={handleClose} list={data} setData={setData} /> :
                                currentLocation.pathname === '/collaborator/role' ? <PostRoleFormTeste cargo={postRoles} titleAction={i18n.t('title.role', { lng: selectedLanguage })} handleClose={handleClose} list={data} setData={setData} /> :
                                    currentLocation.pathname === '/dimension' ? <DimensionFormTest dimension={dimensions} titleAction={i18n.t('title.dimension', { lng: selectedLanguage })} handleClose={handleClose} list={data} setData={setData} /> :
                                        currentLocation.pathname === '/period' ? <PeriodFormTest evaluationPeriod={evaluationPeriod} titleAction={i18n.t('title.period', { lng: selectedLanguage })} handleClose={handleClose} setData={setData} /> :
                                            currentLocation.pathname === '/companies' ? <CreateCompany companySet={companies} companyItems={setCompanies} titleAction={i18n.t('title.company', { lng: selectedLanguage })} handleClose={handleClose} updaters={updaters}
                                                setUpdaters={setUpdaters} setData={setData} /> :
                                                <PeriodForm />
                    }

                </DialogContent>
            </Dialog>
        </Grid >
    )
}

export default SearchAddBar;