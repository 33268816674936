import { Section } from "../section";
import css from "../css/hero.module.css";
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
const { REACT_APP_API_URL } = process.env;

const Header = ({ hero }) => {
  const imageUrl = hero?.avatar? `${REACT_APP_API_URL}${hero?.avatar }`: '/avatar.jpg'

  return (
    <div className={css.hero}>
      <div className={css.avatar}>
        <img src={imageUrl} alt="avatar" />
      </div>
    </div>
  );
};
 
const Content = ({ handleOpen, setStepID, hero, }) => { 
  const scores = hero?.score;

  const [pile, setPile] = useState("");
  useEffect(() => {

    let sum = 0;

    // Iterate over the array and calculate the sum of total/rate
    for (let i = 0; i < scores?.length; i++) {
      const score = scores[i];
      sum += score?.rateTotal / score?.rateLength;
    }
    let sumTotal = sum / scores?.length
    setPile(sumTotal);

  }, []);
  const ClientScore = ({ value, size }) => {
    return (
      <Rating
        value={value}
        precision={0.5}
        readOnly
        size={size} 
        sx={{pt:6}}
      />
    );
  };

 const theHero = hero
  const heroName = hero.name;
  let firstName = heroName?.split(" ")[0]
 
  return (
    <div className={css.content}>
      <div className={css.name}>
        <span className={css.firstName}>{firstName} Rambowski</span>
      
      </div>
      <div className={css.socials}> 
        <span className={css.subtitle}>
          <BusinessCenterOutlinedIcon/> {theHero?.postRoleDescription}<br/>
         {theHero?.companies?.map((historyRow, index) =>  
                      <div key={historyRow.companyID}>
                         <span>
                          {historyRow?.company || 'N/A'}
                        </span>
                      </div>
                     )}  
           <BusinessRoundedIcon/> {theHero?.postRoleDescription} <br />
          <ClientScore value={Number(pile) || 0} size={"large"} />

          <br />
         
        </span>

        
      </div>

       <ul className={css.contacts}>
        <li>
          <h4>Location</h4>
          <span> {theHero?.address} <br /> {theHero?.city} , {theHero?.country}</span>
        </li>
        <li>
          <h4>Phone</h4>
          <span>{theHero?.phone}</span>
        </li>
        <li>
          <a href={theHero?.web}>
            <h4>Web</h4>
            <span>{theHero?.web || "N/A"}</span>
          </a>
        </li>
        <li>
          <a href={`mailto:${theHero?.email}`}>
            <h4>Email</h4>
            <span>{theHero?.email}</span>
          </a>
        </li>
      </ul>   
    </div>
  );
};

export const Hero = ({ handleOpen, setStepID, collaborator, profiles, colaboradorEdit }) => { 
  return <Section 
  header={<Header hero={collaborator} colaboradorEdit={colaboradorEdit} />} 
  content={<Content handleOpen={handleOpen} setStepID={setStepID} profiles={profiles} hero={collaborator} colaboradorEdit={colaboradorEdit} />} hero={true} />;
};
