import { Grid} from "@mui/material";
import { useEffect, useState } from "react";
 
import { useLazyQuery } from "@apollo/client";
import CompanyList from "./Company_list";
import SearchAddBar from "../../../components/TopBar/search-add-bar.component";
import { GET_COMPANIES,GET_USER_DATA } from "./query.gql"; 
import { ToastContainer } from "react-toastify"; 
import CompanyView from "../Company_View_Page/company_detail_main";
import jwtDecode from 'jwt-decode';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Pathway from "../../../components/TopBar/pathway";
import {Alert, AlertTitle, Snackbar} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {  useTheme } from '@mui/material/styles';
 
 
 
const CreateCompanyMain = () => {
  const jwt = localStorage.getItem("jwtToken"); 
  const [companies, setCompanies] = useState([])
  const [company_, setCompany_] = useState(null);
  const [company_Edit, setCompany_Edit] = useState(false); 
  const [currentID, setCurrentID]=useState(null)
  const [filteredList, setFilteredList] = useState('');
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const [updater, setUpdater] = useState([]);  
  const theme = useTheme(); 
 
  const [getCompanys_, { loading, data }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });
 
 const filteredSearch= companies.filter((item) => {
    return item?.attributes?.name?.toLowerCase().includes(filteredList?.toLowerCase())
  }); 
 
  useEffect(() => {
    if (data) {
      const { companies } = data;
      setCompanies(companies.data);
   
    }
  
 
 
  }, [data]);
  useEffect(() => {
    getCompanys_(); 
    
  }, []);
 
 
 const validationEmailForm = yup
  .object({
    
    country: yup.string().required("Country is Mandatory"),
    city: yup.string().required("City is Mandatory"),
    

  })
  .required();
// console.log("CompanyASAS", company_)
 
 
const { 
  register, 
  formState: { errors },
} = useForm({
  resolver: yupResolver(validationEmailForm),
});
const handleCloseAlert = () => {
  setOpenAlertOK(false);
};
  //console.log(companies)
   

  
  return (
 
 
    <Grid container > 
    
      <ToastContainer />  
      <Grid item xs={12}>
     
        {company_ === null ?
   <> 
       <Pathway/>
             {<SearchAddBar 
          updater={updater}
          setUpdater={setUpdater}
             setData={setCompanies} 
              searched={filteredList}
               setSearched={setFilteredList}/>}
                 
          <CompanyList
           updater={updater}
           setUpdater={setUpdater}
           companies_={filteredSearch}
           currentID={currentID}
           setCurrentID={setCurrentID}
            companies={companies}
            setOpenAlertOK={setOpenAlertOK}
            setSuccessMessage={setSuccessMessage}
            company_={company_}
            setCompany_={setCompany_}
            setCompanies={setCompanies}
            company_Edit={company_Edit}
            setCompany_Edit={setCompany_Edit} 
          />
           
         </>
    : company_Edit === true  ? (
         <>
          <Pathway/>
           <SearchAddBar 
            updater={updater}
            setUpdater={setUpdater}
           setData={setCompanies} 
              searched={filteredList}
               setSearched={setFilteredList}/>
          <CompanyList 
            updater={updater}
            setUpdater={setUpdater} 
           setCompanies={setCompanies}
          currentID={currentID}
          setCurrentID={setCurrentID}
          companies_={filteredSearch}
          company_={ company_} 
          setCompany_={setCompany_} 
          setCompany_Edit ={setCompany_Edit} 
          company_Edit={company_Edit} 
          setOpenAlertOK={setOpenAlertOK}
          setSuccessMessage={setSuccessMessage}
          companies={companies}
          /> 
          
        </> 
     )   
  
  : (
          <CompanyView  
           setCompanies={setCompanies}
          companies={companies}
          currentID={currentID}
          setCurrentID={setCurrentID}
          setOpenAlertOK={setOpenAlertOK}
          setSuccessMessage={setSuccessMessage}
          company_={company_} 
          setCompany_={setCompany_} 
          setCompany_Edit={setCompany_Edit} 
          company_Edit={company_Edit}
          />
        )}
 
      </Grid> 
      <Snackbar
                   style={{ backgroundColor: 'teal', color: 'coral' }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openAlertOK}
                    autoHideDuration={5000}
                    onClose={handleCloseAlert}
                >
       <Alert severity="success" sx={{ border: 1 }}> 
    <AlertTitle sx={{ width:500 ,display:'flex',justifyContent:'space-between'}}> Successo <CloseIcon color='secondary' onClick={handleCloseAlert}sx={{ cursor: 'pointer' }}/> </AlertTitle>
    {successMessage}  
   
</Alert></Snackbar>
    </Grid>
)

}
 
 

export default CreateCompanyMain;