import { Section } from "../section";
import css from "../css/hero.module.css";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import Twitter from "@mui/icons-material/Twitter";
import { useEffect, useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { IconButton } from "@mui/material";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
const { REACT_APP_API_URL } = process.env;

const Header = ({ hero, colaboradorEdit }) => {
  return (
    <div className={css.hero}>
      <div className={css.avatar}>
        <img src={`${REACT_APP_API_URL}${hero.avatar}`} alt="avatar" />
      </div>
    </div>
  );
};

const Content = ({ handleOpen, setStepID, hero, profiles, colaboradorEdit }) => {

  const scores = hero.score;
  const evaluationScore = `${scores.length} Evaluations`;
  const [pile, setPile] = useState("");
  useEffect(() => {

    let sum = 0;

    // Iterate over the array and calculate the sum of total/rate
    for (let i = 0; i < scores.length; i++) {
      const score = scores[i];
      sum += score.rateTotal / score.rateLength;
    }
    let sumTotal = sum / scores.length
    setPile(sumTotal);

  }, []);
  const ClientScore = ({ value, size }) => {
    return (
      <Rating
        value={value}
        precision={0.5}
        readOnly
        size={size}
      />
    );
  };

  const theHero = hero
  const heroName = hero.name;
  let firstName = heroName?.split(" ")[0]
  let lastName = heroName?.split(" ")[1]

  const profile = profiles
  const selectedWords = ['Facebook', 'Instagram', 'Twitter'];

  var contactInfo = profile.filter(function (obj) {
    return selectedWords.includes(obj.platform);
  });

  return (
    <div className={css.content}>
      <div className={css.name}>
        <span className={css.firstName}>{firstName}</span>
        <span className={css.lastName}>{lastName}</span>
      </div>
      <div className={css.socials}>
        <span className={css.subtitle}>{theHero.postRole}<br />{theHero.companies?.map((historyRow, index) => (
                      <div key={historyRow.id}>
                        <span>
                          {historyRow?.attributes.name || 'N/A'}
                        </span>
                      </div>
                    ))}
          {evaluationScore}

          <br />
          <ClientScore value={Number(pile) || 0} size={"large"} />

          <br />
          {colaboradorEdit === true && <IconButton color="primary" onClick={() => {
            handleOpen("Edit Collaborator");
            setStepID(hero.id);
          }}>
            <EditOutlinedIcon />
          </IconButton>}
        </span>

        <ul className={css.links}>
          {selectedWords.map((word, index) => {
            const matchingProfile = contactInfo.find((info) => info.platform === word);

            if (matchingProfile) {
              return (
                <li key={index}>
                  <a href={matchingProfile.urlProfile}>
                    {word === 'Facebook' && <Facebook fontSize="large" />}
                    {word === 'Instagram' && <Instagram fontSize="large" />}
                    {word === 'Twitter' && <Twitter fontSize="large" />}
                  </a>
                </li>
              );
            }

            return (
              <li key={index}>
                <a href={`https://www.${word.toLowerCase()}.com`}>
                  {word === 'Facebook' && <Facebook fontSize="large" />}
                  {word === 'Instagram' && <Instagram fontSize="large" />}
                  {word === 'Twitter' && <Twitter fontSize="large" />}
                </a>
              </li>
            );
          })}
        </ul>
      </div>

      <ul className={css.contacts}>
        <li>
          <h4>Location</h4>
          <span> {theHero.address} <br /> {theHero.city} , {theHero.country}</span>
        </li>
        <li>
          <h4>Phone</h4>
          <span>{theHero.phone}</span>
        </li>
        <li>
          <a href={theHero.web}>
            <h4>Web</h4>
            <span>{theHero.web || "N/A"}</span>
          </a>
        </li>
        <li>
          <a href={`mailto:${theHero.email}`}>
            <h4>Email</h4>
            <span>{theHero.email}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Hero = ({ handleOpen, setStepID, collaborator, profiles, colaboradorEdit }) => {
  return <Section header={<Header hero={collaborator} colaboradorEdit={colaboradorEdit} />} content={<Content handleOpen={handleOpen} setStepID={setStepID} profiles={profiles} hero={collaborator} colaboradorEdit={colaboradorEdit} />} hero={true} />;
};
