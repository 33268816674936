import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { GET_USER_DATA } from './query.gql';
import jwtDecode from 'jwt-decode';
import { useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../contexts/auth-context';
import PasswordChangePage from '../PasswordChangePage/password-change.page';
import { ToastContainer } from 'react-toastify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockResetIcon from "@mui/icons-material/LockReset";
import ConstructionIcon from "@mui/icons-material/Construction";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PushPinIcon from "@mui/icons-material/PushPin";
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import QuizIcon from "@mui/icons-material/Quiz";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import FormHelperText from '@mui/material/FormHelperText';
import { FormControl, InputLabel, Select } from '@mui/material';
import PortugalFlag from './portugal-flag.png';
import EnglandFlag from './england-flag.png';
import i18n from '../../translate/i18n'
import LanguageSelector from '../../translate/languages/languageSelector';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import SideBar from '../../components/TopBar/side-bar.component';

const I18N_STORAGE_KEY = 'i18nextLng';

const { REACT_APP_API_URL } = process.env;
function LongMenu() {
    const { selectedLanguage } = useContext(LanguageContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, logout } = useContext(AuthContext);
    let navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenView = () => {
        handleClose();
    };

    const handleNavigation = (url) => {
        navigate(url)
        handleClose();
    };

    const handleClickDelete = () => {
        handleClose();
    };


    return (
        <>
            <IconButton aria-label="more" onClick={handleClick}>
                <DownIcon style={{ border: "0.001rem solid", borderRadius: "20%", marginLeft: "1rem", backgroundColor: '#F5F4F7' }} />
            </IconButton>

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleNavigation("profile")}>
                    <VisibilityOutlinedIcon color='primary' style={{ marginRight: '0.313rem' }} />
                    {i18n.t('options.verPerfil', { lng: selectedLanguage })}
                </MenuItem>
                <MenuItem onClick={() => handleNavigation("password/change")}>
                    <VpnKeyIcon color='primary' style={{ marginRight: '0.313rem' }} />
                    {i18n.t('options.mudarPalavraPasse', { lng: selectedLanguage })}
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                    <ExitToAppIcon color='primary' style={{ marginRight: '0.313rem' }} />
                    {i18n.t('options.sair', { lng: selectedLanguage })}
                </MenuItem>
            </Menu>
        </>
    );
}
const UserProfileCard = () => {
    const jwt = localStorage.getItem('jwtToken');
    const { id: userID } = jwtDecode(jwt);
    const [userData, setUserData] = useState({
        name: '',
        postRole: '',
        userRole: '',
        image: '',
    });

    const [getUserData] = useLazyQuery(GET_USER_DATA);

    useEffect(() => {
        getUserData({
            variables: {
                id: userID,
            },
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
        })
            .then((data) => {
                let userData = data.data.usersPermissionsUser.data;

                setUserData({
                    name: userData?.attributes?.name,
                    postRole: userData?.attributes?.postRole?.data?.attributes?.description,
                    userRole: userData?.attributes?.userRole?.data?.attributes?.description,
                    image: userData?.attributes?.image?.data?.attributes?.url,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);
    const imageUrl = userData?.image ? `${REACT_APP_API_URL}${userData.image}`: '/avatar.jpg'
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '0.5rem' }}>
                    <LanguageSelector location={UserProfileCard.name} />
                </div>
                <Avatar
                    alt={userData.name}
                    src={imageUrl}
                    sx={{
                        borderRadius: '20%',
                        width: '2.5rem',
                        height: '2.5rem',
                        transition: 'transform 0.3s ease',
                        /* '&:hover': {
                            transform: 'scale(1.5)',
                        }, */
                    }}
                />
                <Box sx={{ ml: 1, mr: -2 }}>
                    <span style={{ fontWeight: '1.5rem', fontSize: '0.8rem', color: 'black', display: 'block' }}>
                        {userData.name}
                    </span>
                    <span style={{ fontSize: '0.6rem', color: '#666666', display: 'block' }}>
                        {userData.userRole}
                    </span>
                </Box>

                <LongMenu />
            </Box>
            <ToastContainer />
        </>
    );
};

export default UserProfileCard;
