import { gql } from "@apollo/client";
export const GET_COLLABORATORS = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
        name
        email 
        phone
        web
        intro 
        address
        isActive 
        evaluation_scores{data{id attributes{evaluation_period{data{id }} evaluation_answer_closeds{data{id attributes{rate evaluation_question_closed{data{id attributes{question type}}}}}} evaluation_question_opens {
          data {
            id
            attributes {
              question
              evaluation_answer_opens {
                data {
                  id
                  attributes {
                    answer
                  }
                }
              }
            }
          }
        }collaborator{data{id attributes{name}}}company{data{id attributes{name  collaborators { data { id  attributes{evaluation_periods{data{id}}}} }}}}}}}
        postRole{data{id attributes{postRole description}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}}
        experiences(pagination:{pageSize:20}){data{id attributes{ description startDate endDate postRole{data{id attributes{postRole description}}} expEntity{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        educations(pagination:{pageSize:20}){data{id attributes{ description startDate endDate course{data{id attributes{name}}}faculty{data{id attributes{name}}}country{data{id attributes{name}}}}}}
        expertise(pagination:{pageSize:20}){data{id attributes{description expertise{data{id attributes{name}}}}}}  
        skills(pagination:{pageSize:20}){data{id attributes{percentual itTool{data{id attributes{name}}}}}}
        profiles(pagination:{pageSize:20}){data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        awards(pagination:{pageSize:20}){data{id attributes{name description}}} 
        portfolios(pagination:{pageSize:20}){data{id attributes{name description image{data{id attributes{url previewUrl}}}}}}
        client{data{id attributes{companies{data{id attributes{name image{data{id attributes{url}}}}}}}}}
        users_permissions_user{data{id attributes{username}}}  
        companies {
          data {
            id
            attributes {
              email
              name 
              users_permissions_user{data{id }}
              evaluation_periods(filters:{isActive:{eq:true}}) {
                data {
                  id
                  attributes {
                    isActive
                    start
                    end
                    collaborators{data{id attributes{name evaluation_scores{data{id attributes{ 
                      evaluation_period{data{id}}evaluation_question_opens{data{id attributes{question isActive evaluation_answer_opens{data{id attributes{answer }}}}}} company{data{id attributes{name}}}}}} 
                    image{data{id attributes{url}}}}}}
                  }
                }
              }
            }
          }
        }      

      }
    }
  }
}
`;

export const GET_INDICATORS = gql`
  query Indicators {
    indicators(pagination: {pageSize: 1000 }) {
      data {
        id
        attributes {
          indicator 
          evaluation_question_opens{data{id attributes{question isActive type
            evaluation_answer_opens{data{id attributes{answer }}}}}}         
          evaluation_question_closeds{data{id attributes{question isActive type evaluation_answer_closeds{data{id attributes{answer rate}}}}}}
          
        }
      }
    }
  }
`;
export const GET_COMPANIES = gql`
query Companies($pagination: PaginationArg) {
  companies(pagination: $pagination  )  {
    data  {
      id
      attributes {
        evaluation_periods{data{id attributes{start end isActive collaborators{data{id attributes{name}}}}}}   
        collaborators{data{attributes{ name intro}}}
        status
        name     
        users_permissions_user{data{id attributes{username confirmed}}} 
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        email
        phone
        branchOfActivity
        image{data{id attributes{url}}}
        findDevs {
          data {
            id
            attributes {
              description
              company_answers {
                data {
                  id
                  attributes {
                    name
                    company_question{data{id attributes{name}}}
                  
                  }}}}}}}}}}
`;
export const EVAL_PERIODS=gql`
query EvaluationPeriods {
  evaluationPeriods{
  
    data {
      id
      attributes {
        description
        start
        end
        isActive
        company{data{id attributes{name users_permissions_user{data{id }}}}}
        collaborators{data{id attributes{name users_permissions_user{data{id }}}}}
      }
    }
  }
}

`
export const EVAL_PERIODS_BY_ID=gql`
query evaluationPeriodsByCompany($id:ID!) {
  evaluationPeriods(filters:{isActive:{eq:true},company:{id:{eq:$id}}}) {
   meta{pagination{total}}
    data {
      id
      attributes {
        company{data{id attributes{
          evaluation_periods {
          data {
            id
            attributes {collaborators{data{id }}
          
          }
          }
        }}}}
        description
        start
        end
      collaborators{data{id attributes{     
          name
          email
          phone
          web
          intro
          address        
          evaluation_scores {
            data {
              id 
              attributes {
                evaluation_period{data{id }} 
              
                      evaluation_answer_opens {
                        data {
                          id
                          attributes{
                            answer
                          }
                        }
                      }
                 evaluation_answer_closeds {
                        data {
                          id
                          attributes {
                            answer
                          }
                        }
                }
              
              }
            }
          }
          postRole{data{id attributes{postRole description}}}
          companies{data{id attributes{name }}}
          image{data{id attributes{url}}} }}}
      }
    }
  }
}
`
export const EVAL_SCORES_COLLABORATOR=gql`
query evaluationScore($id:ID!) {
  evaluationScores(filters:{collaborator:{id:{eq:$id}}}) {
    data {
      id 
      attributes { 
        evaluation_answer_opens{data{id attributes{answer evaluation_question_open{data{id attributes{question}}}}}}      
        evaluation_period {
          data {
            id
            attributes {
              description
            }
          }
        }
      }
    }
  }
}
`
export const EVAL_PERIODS_COLLABORATOR=gql`
query evaluationPeriod($id:ID!) {
  evaluationPeriod(filters:{collaborator:{id:{eq:$id}}}) {
    data {
      id
      attributes { 
       description
      }
       
     }}  
}
` 
export const EVAL_OPEN_ANSWERS=gql`
query evaluationAnswerOpens{
  evaluationAnswerOpens {
    data {
      id
      attributes {
        answer
      }
    }
  }
}
`
 export const EVAL_SCORES_COMPANY=gql`
query evaluationScore($id:ID!) {
  evaluationScores(filters:{company:{id:{eq:$id}}}) {
    data {
      id 
      attributes { 
        evaluation_answer_opens{data{id attributes{answer evaluation_question_open{data{id attributes{question}}}}}} 
        collaborator{data{id}}
        evaluation_period {
          data {
            id
            attributes {
              description
            }
          }
        }
      }
    }
  }
}
`