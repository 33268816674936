const messages = {
    en: {
        translations: {
            login: {
                login: {
                    access: "Access",
                    signUp: "Sign Up",
                    signMessage: "Don't Have an Account Yet?",
                    welcome: "Welcome",
                },
                register: {
                    title: "Create an account as company",
                    userName: "User Name",
                    companyName: "Company Name",
                    branchActivity: "Branch of Activity",
                    email: "Email",
                    emailConfirm: "Confirm Email",
                    password: "Password",
                    passwordConfirm: "Confirm Passowrd",
                },
                error: {
                    countrySelect: "Please Select a Country!",
                    citySelect: "Please Select a City!",
                    countryFetch: "There was an error when loading Countries!",
                    userFetch: "There was an error when loading Data!",
                    userCreation: "There was an error creating the user!",
                    username: "Username Already in Use!",
                    name: "A Company with this name has been Registered Already!",
                    usernameBody: "Please Select a New User Name",
                    usernameError: "User Name is Mandatory",
                    emailSelect: "The email is already in use!!",
                },
                button: {
                    login: "Already Have an Account? Login!",
                    toRegister: "No Account?? Register your Company Now!",
                    register: "Register",
                    back: "<< Back",
                    access: "Access",

                },
            },
            loading: {
                loading: 'Loading...'
            },
            form: {
                duplicated: 'already exists!',
                duplicatedError: 'Please change!',
                mandatory: "Can't be empty!",
            },
            error: {
                createPeriod: 'Check the data and try again!',
                updatePeriod: 'Something went wrong! Refresh and try again!',
                deletePeriod: 'There was an error during delete!',
            },
            success: {
                create: ' created successfully!',
                update: ' updated successfully!',
                delete: ' deleted successfully!',
                createPeriod: 'New Evaluation Period created successfully!',
                updatePeriod: 'Evaluation Period updated successfully!',
                deletePeriod: 'Evaluation Period deleted successfully!',
                createRole: 'New Role created successfully!',
                updateRole: 'Role updated successfully!',
                deleteRole: 'Role deleted successfully!',
                createDimension: 'New Dimension created successfully!',
                updateDimension: 'Dimension updated successfully!',
                deleteDimension: 'Dimension deleted successfully!',
            },
            alert: {
                error: 'Error',
                success: 'Success',
                info: 'Info',
                warning: 'Warning'
            },
            pathway: {
                collaborator: 'collaborator',
                create: 'Create',
                role: 'Role',
                companies: 'Companies',
                evaluation: 'Evaluation',
                period: 'Period',
                dimension: 'Dimension',
                indicator: 'Indicator',
                question: 'Question',
                quiz: 'Quiz',
            },
            title: {
                indicator: 'New Indicator',
                editIndicator: 'Edit Indicator',
                viewIndicator: 'View Indicator',
                confirm: "Confirm Operation",
                question: "New Question",
                quiz: "New Quiz",
                role: "New Role",
                editRole: "Edit Role",
                viewRole: "View Role",
                dimension: "New Dimension",
                editDimension: "Edit Dimension",
                viewDimension: "View Dimension",
                collaborator: "New Collaborator",
                period: "New Evaluation Period",
                editPeriod: "Edit Evaluation Period",
                viewPeriod: "View Evaluation Period",
                company: "New Company",
                delete: "Are you sure you want to delete?",
            },
            tittles: {
                dashboard: 'Dashboard',
                company: 'Companies',
                collaborator: 'Collaborator',
                evaluation: 'Evaluation',
                evaluationManager: 'Evaluation Manager',
                parameterization: 'Parameterization',
                role: 'Role',
                dimension: 'Dimension',
                priod: 'Period',
                indicator: 'Indicator',
                questao: 'Question',
                quiz: 'Quiz',

            },
            options: {
                verPerfil: 'View Profile',
                mudarPalavraPasse: 'Change Password',
                sair: 'Logout',
                submit: 'Submit',
                view: 'View',
                edit: 'Edit',
                delete: 'Delete',
                enable: 'Enable',
                disable: 'Disable',
                active: 'Active',
                inactive: 'Inactive',
                evaluate: "Evaluate"
            },
            dashboard: {
                welcome: 'Welcome, {{name}}',
                review: 'Evaluation',
                graph: 'Evaluation Chart',
                history: 'Evaluation History',
                last: 'Last Evaluations',
                questions: 'Answer These Questions',
                firstWelcome: 'Afrikan Coders will contact you',
                error: 'Something went Wrong, Refresh and Try Again!',
            },
            month: {
                jan: 'Jan',
                feb: 'Feb',
                mar: 'Mar',
                apr: 'Apr',
                may: 'May',
                jun: 'Jun',
                jul: 'Jul',
                aug: 'Aug',
                sep: 'Sep',
                oct: 'Oct',
                nov: 'Nov',
                dec: 'Dec'
            },
            table: {
                name: 'Name',
                location: 'Location',
                email: 'Email',
                phone: 'Phone',
                company: 'Company',
                state: 'State',
                action: 'Action',
                description: 'Description',
                startDate: 'Start Date',
                endDate: 'End Date',
                dimension: 'Dimension',
                question: 'Question',
                questionType: 'Question Type',
                indicator: 'Indicator',
                branch: 'Branch',
                status: 'Status',
                role: 'Role',
            },
            button: {
                evaluation: 'Evaluations',
                performed: 'Performed',
                pending: 'Pending',
                new: 'New',
                filter: 'Filters',
                companyProfile: 'Company Profile',
                questionsandanswers: "Questions & Answers",
                next: "Next",
                cancel: "Cancel",
                save: "Save",
                confirm: "Confirm",
            },
            collaborator: {
                alert: {
                    creationSuccess: 'Collaborator created successfully!',
                    creationEmailWarning: "Email already in use!",
                    creationError: "Check the data and try again!",
                },
                title: {
                    collaborator: 'Collaborator',
                    experience: "Experience",
                    education: "Education",
                    expertise: 'Expertise',
                    skill: "Skill",
                    profile: "Profile",
                    award: "Award",
                    portfolio: "Portfolio",
                },
                stepper: {
                    collaborator: 'Personal Data',
                    experience: "Experience",
                    education: "Education",
                    expertise: 'Expertise',
                    skill: "Skill",
                    profile: "Profile",
                    award: "Award",
                    portfolio: "Portfolio",
                },
                subtitle: {
                    collaborator: ' Create Collaborator',
                    experience: " Create Experience",
                    education: " Create Education",
                    expertise: ' Create Expertise',
                    skill: " Create Skill",
                    profile: " Create Profile",
                    award: " Create Award",
                    portfolio: " Create Portfolio",
                },
                button: {
                    prev: "Previous",
                    next: "Next",
                    end: "Finish"
                }

            },
            collaboratorStep: {
                collaborator: {
                    name: 'Name',
                    nameCollaborator: 'Collaborator Name',
                    nameProfile: 'Platform Name',
                    nameSkill: 'Skill Name',
                    nameExpertise: 'Expertise Name',
                    nameFaculty: 'Faculty Name',
                    address: "Address",
                    country: "Country",
                    course: "Course",
                    countryBody: "Ex:Cape Verd",
                    city: 'City',
                    cityBody: 'Ex: Praia',
                    company: 'Company',
                    description: 'Description',
                    descriptionBody: 'How was your experience?',
                    descriptionExpertise: 'Details of your expertise!',
                    descriptionProfile: 'What can we expect to find?',
                    descriptionAward: 'Describe your award',
                    descriptionPortfolio: 'Describe your work',
                    role: "Role",
                    roleBody: "Ex: Programmer",
                    phone: "Phone",
                    web: "Web",
                    email: "Email",
                    introduction: "Introduction",
                    introductionBody: "Ex: I am the storm that is approaching...",
                    upload: "Collaborator Picture",
                    newUpload: "Change Image",
                    uploadPortfolio: "Add an Image",
                    entity: "Entity Name",
                    start: "Start Date",
                    end: "End Date",
                    profileUrl: "Profile Url",
                    percentage: "Percentage",
                    percentageBody: "Add a Number from 0 to 100",
                },
                button: {
                    upload: "Upload",
                    collaborator: "Add Collaborator",
                    experience: "Add Experience",
                    education: "Add Education",
                    expertise: "Add Expertise",
                    skill: "Add Skill",
                    profile: "Add Profile",
                    award: "Add Award",
                    portfolio: "Add Portfolio",
                    cancel: "Cancel",
                    edit: "Edit",
                    create: "Create",
                    add: "Add",
                },
                error: {
                    collaborator: 'A Collaborator Account Must Be Created First!',
                    uploadFile: 'Please select a valid image file (JPEG, PNG, or GIF)',
                    uploadReady: ' - is ready for upload!',
                    nameSelect: "Name is Mandatory",
                    entitySelect: "Entity is Mandatory",
                    countrySelect: "Country is Mandatory",
                    citySelect: "City is Mandatory",
                    roleSelect: "Role is Mandatory",
                    portfolioSelect: "Role is Mandatory",
                    profileSelect: "Platform is Mandatory",
                    urlSelect: "Url is Mandatory",
                    awardSelect: "Url is Mandatory",
                    skillSelect: "Skill is Mandatory",
                    expertiseSelect: "Skill is Mandatory",
                    facultySelect: "Faculty is Mandatory",
                    courseSelect: "Course is Mandatory",
                },
            }
        }
    }
}

export { messages }