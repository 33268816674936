import { Grid } from "@mui/material";
import PeriodListTest from "./period-listtest.component";
import SearchAddBar from "../../components/TopBar/search-add-bar.component";
import { GET_COLLABORATORS, GET_EVALUATION_PERIODS } from "./query.gql";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Pathway from "../../components/TopBar/pathway";

const PeriodPage = () => {
    const jwt = localStorage.getItem("jwtToken");
    const [evaluationPeriods, setEvaluationPeriods] = useState([])
    const [collaborators, setCollaborators] = useState([])
    const [filteredList, setFilteredList] = useState('');

    const [getEvaluationPeriods, { loading, data }] = useLazyQuery(GET_EVALUATION_PERIODS, {
        context: {
            headers: {
                authorization: `Bearer ${jwt}`,
            },
        },
        fetchPolicy: 'cache-and-network',
    });
    const [getCollaborators] = useLazyQuery(GET_COLLABORATORS);

    useEffect(() => {
        getEvaluationPeriods();

    }, []);

    useEffect(() => {
        if (data) {
            const { evaluationPeriods } = data;
            setEvaluationPeriods(evaluationPeriods.data);

        }
        getCollaborators({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            fetchPolicy: 'cache-and-network',
          }).then((data) => {
            const collaboratorData = data.data.collaborators.data;
            setCollaborators(collaboratorData);
          });
          

}, [data,]);


const filteredSearch = evaluationPeriods.filter((item) => {
    return item.attributes.description.toLowerCase().includes(filteredList.toLowerCase())
});
return (
    <Grid container maxWidth="lg" style={{ margin: "0 auto" }}>

        <Grid item xs={12}>
        <Pathway/> 
            <SearchAddBar
                searched={filteredList}
                setSearched={setFilteredList}
                setData={setEvaluationPeriods}
            />
        </Grid>
        <Grid item xs={12}>
            <PeriodListTest
                evaluationPeriods={filteredSearch}
                setEvaluationPeriods={setEvaluationPeriods}
                collaborators={collaborators}
            />
        </Grid>
    </Grid>
);

}

export default PeriodPage;