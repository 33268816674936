import { gql } from "@apollo/client";

export const UPDATE_EVAL_OPEN = gql`
mutation UpdateEvaluationAnswerOpen($id:ID!,$data: EvaluationAnswerOpenInput!)  {
  updateEvaluationAnswerOpen(id:$id,data: $data)  {
    data {
      id
      attributes {
        evaluation_question_open {
            data {
              id
              attributes {
                question
                evaluation_answer_opens {
                  data {
                    id
                    attributes {
                      answer
                    }
                  }
                }
              }
            }
          } 
      }
    }
  }
}

`;
export const UPDATE_EVAL_CLOSED= gql`
mutation UpdateEvaluationAnswerClosed($id:ID!,$data: EvaluationAnswerClosedInput!)  {
  updateEvaluationAnswerClosed(id:$id,data: $data)  {
    data {
      id
      attributes {
        evaluation_question_close {
            data {
              id
              attributes {
                question
                evaluation_answer_closeds {
                  data {
                    id
                    attributes {
                      answer
                    }
                  }
                }
              }
            }
          } 
      }
    }
  }
}

`;
export const CREATE_EVAL_SCORE = gql`
mutation createEvaluationScore($data: EvaluationScoreInput!)  {
  createEvaluationScore( data: $data)  {
    data {
      id
      attributes {
        evaluation_answer_opens {
            data {
              id
              attributes {
                answer
              }
            }
          }
          evaluation_answer_closeds {
            data {
              id
              attributes {
                answer
              }
            }
          }
      }
    }
  }
}

`;


export const CREATE_PERIODS_COLLABORATOR=gql`
mutation evaluationScore($data: EvaluationScoreInput!) {
  createEvaluationScore(data: $data) {
    data {
      id
      attributes {
        evaluation_period {
          data {
            id
            attributes {
              description
            }
          }
        }
      }
    }
  }
}
`

export const CREATE_OPEN_ANSWER=gql`
mutation createOpenAnswer($data: EvaluationAnswerOpenInput!) {
  createEvaluationAnswerOpen(data: $data) {
    data {
      id
      attributes {
        answer
        evaluation_question_open {
          data {
            id
            attributes {
              question
            }
          }
        }
      }
    }
  }
} 
`

export const CREATE_CLOSED_ANSWER=gql`
mutation createClosedAnswer($data: EvaluationAnswerClosedInput!) {
  createEvaluationAnswerClosed(data: $data) {
    data {
      id
      attributes {
        answer
        evaluation_question_closed {
          data {
            id
            attributes {
              question
            }
          }
        }
      }
    }
  }
} 
`
export const DELETE_EVAL_SCORE=gql`
mutation DeleteScore($id:ID!){
  deleteEvaluationScore(id:$id) {
    data {
      id
    }
  }
}
`
export const EVAL_PERIODS_DELETE=gql`
mutation Delete_PERIODS($id: ID!) {
  deleteEvaluationPeriod(id: $id) {
    data {
      id
      attributes {
        description
      }
    }
  }
}
`
export const UPDATE_EVAL_SCORE=gql`
mutation($id:ID!, $data: EvaluationScoreInput!) {
  updateEvaluationScore(id: $id, data: $data) {
    data {
      id
    
    }
  }
}

`
export const DELETE_EVAL_OPEN=gql`
mutation DeleteAnswerOpen($id:ID!){
  deleteEvaluationAnswerOpen(id:$id) {
    data {
      id
    }
  }
}`