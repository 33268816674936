import { Section } from "../section";
import VerticalLinearStepper from "../sections/create_indicator_stepper";
import ContentStepper from "../sections/indicateContent";
import { useEffect, useState } from "react";

const Header = ({ indicate, handleStep, indicators, handleStepClick, step, hero }) => {
 return (
    <>
 <VerticalLinearStepper
          step={step}
          indicate={indicate}
          indicators={indicators}
          handleStepClick={handleStepClick}
          handleStep={handleStep}
          hero={hero}
        />

    </>
  );
};

const Content = ({matchStep, matchSteps,indicate, handleStepClick, step, handleStep ,matchStepOpen, hero,company_id, setCompany_id,companyEditEval,setCompanyEditEval , btnState, setBtnState,answer_Id, question_Id, score_Id ,set_Answer_Id,  set_Score_Id,  set_Question_Id ,showEvals,setShowEvals ,closedAnsID,setStep, duplicatSteps, answer_,set_answer_, scoreFilter,  setScoreFilter, i_, setI_ }) => {
 
  return (
    <>
    <ContentStepper
  duplicatSteps={duplicatSteps}
  step={step}
  indicateQA={matchStep}
  matchSteps={matchSteps}
  indicate={indicate}
  handleStepClick={handleStepClick}
  answer_Id={answer_Id}
  score_Id={score_Id}
  question_Id={question_Id}
  set_Answer_Id={set_Answer_Id}
  set_Score_Id={set_Score_Id}
  set_Question_Id={set_Question_Id}
  handleStep={handleStep}
  matchStepOpen={matchStepOpen}
  hero={hero}
  company_id={company_id}
  setCompany_id={setCompany_id}
  btnState={btnState}
  setBtnState={setBtnState}
  showEvals={showEvals}
  setShowEvals={setShowEvals}
  closedAnsID={closedAnsID}
  companyEditEval={companyEditEval}
  setCompanyEditEval={setCompanyEditEval}
  setStep={setStep}
  answer_={answer_}
  set_answer_={set_answer_}
  scoreFilter={scoreFilter}
setScoreFilter={setScoreFilter}
i_={i_}
setI_={setI_}
 />
    </>
  );
};

export const Indicate = ({handleOpen, indicate, indicators,collaborator,company_id,setCompany_id, btnState, setBtnState, showEvals, setShowEvals, companyEditEval,setCompanyEditEval,answer_Id, question_Id, score_Id, set_Answer_Id, set_Score_Id,set_Question_Id, step, setStep, answer_, set_answer_, scoreFilter, setScoreFilter, loading, setLoading, i_, setI_}) => {
   
const [matchStep, setMatchStep] = useState(null);
const [matchSteps, setMatchSteps] = useState([]);
const [matchStepOpen, setMatchStepOpen] = useState(null);
const [matchStepClosed, setMatchStepClosed] = useState(null);

const indicateTypeOpen = indicators.filter((ind)=>
 ind?.attributes?.evaluation_question_opens?.data.length !== 0)
const indicateTypeClosed = indicators.filter((ind)=>
ind?.attributes?.evaluation_question_closeds?.data.length !== 0)
const indQAOpen = indicateTypeOpen.map((tx)=> tx?.attributes?.evaluation_question_opens?.data )
 
const indQAClosed = indicateTypeClosed.map((tx)=> tx?.attributes?.evaluation_question_closeds?.data) 

const openCloseQA=indQAOpen.concat(indQAClosed)
const openCloseQS=indicateTypeOpen.concat(indicateTypeClosed)

const indicatorIdx = openCloseQS.map((tx)=> tx.attributes.indicator)
const duplicatSteps =openCloseQS.filter((item, index) => indicatorIdx?.indexOf(item?.attributes?.indicator) === index)


 const handleStep = (stepIndex ,i) => {
  setStep(stepIndex);
  //main question step
    setMatchStep(openCloseQA[stepIndex])
     setMatchSteps(duplicatSteps[stepIndex])   
  //used to elimnitate duplicte in the indicator steps in create indicator 
  setMatchStepOpen(indQAOpen[stepIndex])
  setMatchStepClosed(indQAClosed[stepIndex])
  
}
useEffect(()=> {
  setMatchSteps(duplicatSteps[step])  
},[step])
 
  return <Section header={<Header indicate={indicate} handleOpen={handleOpen}
  matchStepOpen={matchStepOpen} matchStep={matchStep} handleStep={handleStep}hero={collaborator} indicators={indicators} indQAOpen={indQAOpen} indQAClosed={indQAClosed} btnState={btnState} setBtnState={setBtnState} matchStepClosed={matchStepClosed}step={step}/>} content={<Content setCompany_id={setCompany_id}company_id={company_id} handleStep={handleStep}hero={collaborator}matchSteps={matchSteps} matchStep={matchStep} matchStepOpen={matchStepOpen} matchStepClosed={matchStepClosed} indicate={indicate}  step={step} duplicatSteps={duplicatSteps} btnState={btnState} setBtnState={setBtnState}showEvals={showEvals} companyEditEval={companyEditEval} setCompanyEditEval={setCompanyEditEval} setShowEvals={setShowEvals}answer_Id={answer_Id} set_Answer_Id={set_Answer_Id}set_Score_Id={set_Score_Id} score_Id={score_Id}question_Id={question_Id} set_Question_Id={set_Question_Id} setStep={setStep}set_answer_={set_answer_} answer_={answer_} scoreFilter={scoreFilter} setScoreFilter={setScoreFilter}loading={loading}
   setLoading={setLoading} i_={i_} setI_={setI_} />}/>;
};
 