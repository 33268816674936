import { Grid } from "@mui/material";
import AnalyticsItem from "./analytics-item";
import i18n from "../.././translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import { useContext } from "react";

const Analytics = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <AnalyticsItem
          btnName={i18n.t('button.evaluation', { lng: selectedLanguage })}
          count="0"
          bgcolor="#F9FAFE"
          url="evaluation/all/list"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AnalyticsItem btnName={i18n.t('button.performed', { lng: selectedLanguage })} count="0" bgcolor="#F9FAFE" />
      </Grid>
      <Grid item xs={12} md={4}>
        <AnalyticsItem btnName={i18n.t('button.pending', { lng: selectedLanguage })} count="0" bgcolor="#F9FAFE" />
      </Grid>
    </Grid>
  );
};

export default Analytics;
