 
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import {Card, CardActions, Typography} from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Pagination } from '@mui/material';
import React,{ useEffect, useState,useRef, useContext } from 'react'; 
import './company.css';
import CompanyDelete from './Company_delete';
import CreateCompany from '../Company_Creation_Page/create_company' 
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import { UserRoleContext } from "../../../contexts/userRole-context";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
  }
}));

 
export default function CompanyList({  
setCompanies,
company_,
setCompany_,
setCompany_Edit,
currentID,
setCurrentID,  
company_Edit,
companies_, 
setOpenAlertOK,
setSuccessMessage,
updater,
setUpdater  
}) {
 
const [currentPage, setCurrentPage] = useState(1);
const [openModal, setOpenModal] = useState(false);
const [activeOption,setActiveOption]=useState(false)
const [companyUser,setCompanyUser ] = useState(""); 
const [sortedPage, setSortedPage] = useState(companies_);

const { selectedLanguage } =  useContext(LanguageContext);
const { uRoleContext } = useContext(UserRoleContext);
 const itemsPerPage =4;  
      
  // Calculate the indexes of the first and last items to display on the current page
  const lastIndex = currentPage* itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage; 

// Extract the items to display on the current page
 const currentItems = sortedPage?.slice(firstIndex, lastIndex);

// Calculate the total number of pages
  const totalPages = Math.ceil( sortedPage?.length / itemsPerPage);

// Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
      
  }; 
  useEffect(() => {
    setCurrentPage(1);
    setSortedPage(companies_) 
   
}, [companies_,totalPages ]);

const [companyID, setCompanyID] = useState(false);
const [company, setCompany] = useState("");
const [open, setOpen] = useState(false);

 
function handleClickDelete( companyID, companyData) {

    setCompanyID(companyID)
   setCompanyUser(companyData?.attributes?.users_permissions_user?.data); 

    setOpen(true)
    setActiveOption(false)
    let data = companies_.find(d => d.id === companyID)
    setCompany(data)

  };
 

const clickDisable=(companyID,companyData)=>{
  setActiveOption(true)
       setOpen(true)
       setCompanyID(companyID)
       
       let data = companies_.find(d => d.id === companyID)
    setCompany(data)
     setCompanyUser(data);
    
}
 
  function handleClickOpenView(companyID, companyData) {

  setCompany_Edit(false)
 let data = companies_.find(d => d.id === companyID)
 
 setCompany_(i => {
     return {
id: data.id,
userID:data?.attributes?.users_permissions_user?.data?.id,
name: data?.attributes?.name,
country: data?.attributes?.country?.data?.attributes?.name,
city: data?.attributes?.city?.data?.attributes?.name,
email: data?.attributes?.email, 
phone: data?.attributes?.phone,
status: data?.attributes?.status,
branchOfActivity: data?.attributes.branchOfActivity,
image: data?.attributes?.image?.data?.attributes.url,
imageID: data?.attributes?.image?.data?.id,
findDevs: data?.attributes?.findDevs?.data ,
countryID: data?.attributes?.country?.data?.id,
cityID: data?.attributes?.city?.data?.id,
confirmed: data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
     }
 })

  };
 
  function handleClose() {
    setOpen(false);
    setOpenModal(false);
   // setCompanies(companies)
 
  };
  function handleEdit(companyID, companyData) {
  //setCompany_(companyData);
    setCompany_Edit(true);
    setOpenModal(true)
    setCurrentID(companyID)
    let data = companies_.find(d => d.id === companyID)
 
    setCompany_(i => {
        return {
  id: data.id,
  userID:data?.attributes?.users_permissions_user?.data?.id,
  name: data?.attributes?.name,
  country: data?.attributes?.country?.data?.attributes?.name,
  city: data?.attributes?.city?.data?.attributes?.name,
  email: data?.attributes?.email, 
  phone: data?.attributes?.phone,
  status: data?.attributes?.status,
branchOfActivity: data?.attributes.branchOfActivity,
image: data?.attributes?.image?.data?.attributes.url,
imageID: data?.attributes?.image?.data?.id,
findDevs: data?.attributes?.findDevs?.data ,
countryID: data?.attributes?.country?.data?.id,
cityID: data?.attributes?.city?.data?.id,
confirmed: data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
        }
    })

  }; 
  
  const [openTarget, setOpenTarget] = useState(false);
  const menuRef = useRef(null);
  const [display, setDisplay] = useState(-1);
  const toggleElement = (currentIndex) => {
 setOpenTarget(!openTarget)
setDisplay(currentIndex);
     
  };


  useEffect(() => {
     
    const handleOutsideClick = (event) => {
      if ( menuRef.current&& !menuRef.current.contains(event.target)) {
    setOpenTarget(false)
      }

    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

   
 //console.log(companies_)

 return (
    <Paper>
       
      <Paper component={Paper}sx={{ padding: 2, marginBottom: 10 }}   
  > 
        <TableContainer >
          <Table sx={{minWidth: 900, overflow:'hidden' }}aria-label="customized table">
            <TableHead >
              <TableRow >
                <StyledTableCell component="th" sx={{ padding:3,}}>{i18n.t('table.name', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell component="th" sx={{ padding:3}}>{i18n.t('table.location', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell component="th" sx={{ padding:3}}>{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell component="th" sx={{ padding: 3}}>{i18n.t('table.email', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell component="th" sx={{ padding:3 }}>{i18n.t('table.phone', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell component="th" sx={{ padding:3}}>{i18n.t('table.status', { lng: selectedLanguage })}</StyledTableCell>                
                <StyledTableCell component="th" sx={{ padding: 3}}>{i18n.t('table.branch', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell component="th" sx={{ padding: 3}}>{i18n.t('table.action', { lng: selectedLanguage })}</StyledTableCell>
              </TableRow>
            </TableHead> 
   
           <TableBody>
              {currentItems?.map((row, index) => (
                    <StyledTableRow key={row.id} >
                   <StyledTableCell scope="row" sx={{}}>
                        {row?.attributes?.name || "N/A"}
                      </StyledTableCell>
                    
                      <StyledTableCell scope="row" sx={{}}>
                         {row?.attributes?.country?.data?.attributes?.name|| "N/A"}
                      </StyledTableCell> 
                        <StyledTableCell scope="row" sx={{}}>
                        {row?.attributes?.city?.data?.attributes?.name || "N/A"}  
                      </StyledTableCell> 
                      <StyledTableCell scope="row" sx={{ padding: 2,wordBreak:"break-all"}}>
                        {row?.attributes?.email || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell scope="row" sx={{}}>
                        {row?.attributes?.phone || "N/A"}</StyledTableCell> 
                      <StyledTableCell >
                        {row?.attributes?.status || "N/A" }
                      </StyledTableCell>
                      <StyledTableCell scope="row"sx={{ }} >
                      {row?.attributes?.branchOfActivity || "N/A"}
                    </StyledTableCell> 
                   
                      <StyledTableCell sx={{}}> 
                 <IconButton 
                       aria-label="view" 
                       color="secondary"
                          onClick={() => toggleElement(index)} >
                       <MoreVertIcon/>
                          </IconButton>
            
                     </StyledTableCell>  
                       
                     <StyledTableCell>
                      <>
                     {openTarget && display ===index? 
        <Card ref={menuRef}variant="outlined" sx={{position:'absolute',right: 170, width: 150}} >
       
             <CardActions
    disableSpacing> 
           
      <IconButton aria-label="view" 
      
        onClick={() => handleClickOpenView(row.id, row)}
      >
        
      <VisibilityOutlinedIcon color="primary"/>
  
         <Typography sx={{ paddingLeft:1 }}  id={row.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography> 
      </IconButton> 
      
  </CardActions> 
  {row?.attributes?.status ==='Active' || uRoleContext === 'administrator'?  <CardActions
    disableSpacing  >   
  <IconButton aria-label="view" onClick={() => handleEdit(row.id, row)} >
        <EditOutlinedIcon color="primary" />
         <Typography sx={{ paddingLeft:1 }} id={row.id}>{i18n.t('options.edit', { lng: selectedLanguage })}</Typography> 
      </IconButton>    
      
  </CardActions>:''}

  <CardActions
    disableSpacing >
  <IconButton aria-label="view"
  onClick={() => handleClickDelete(row.id, row )}
>

  <ClearOutlinedIcon color="primary" />  
  <Typography sx={{ paddingLeft:1 }}id={row.id}> {i18n.t('options.delete', { lng: selectedLanguage })} </Typography> 
</IconButton>

  </CardActions>
  
  <CardActions

    disableSpacing >
  <IconButton aria-label="view"
  onClick={()=> clickDisable(row.id, row )}
 
>
< DoNotDisturbAltIcon color={row?.attributes?.status === 'Active' ?'disabled':'primary'}  />
  
  <Typography sx={{ paddingLeft:1 }}id={row.id} >{row?.attributes?.status  === 'Active' ? i18n.t('options.disable', { lng: selectedLanguage }) : i18n.t('options.enable', { lng: selectedLanguage })} </Typography>  
</IconButton> 
 
  </CardActions>
 </Card>:(
 <></>
)}
</>
</StyledTableCell> 

</StyledTableRow>

))} 
    </TableBody>  

</Table>
</TableContainer>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Pagination
            shape="rounded"
            size="small"
            color="primary"
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              paddingTop: 13,
              paddingBottom: 3,
              position: "relative",
              float: "right"
            }}
          />
        </Box>
      </Paper>
      
       <Dialog
       
        open={open}
          onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
       
      <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          {
            activeOption === false ? (
              <p>Delete {company.attributes?.name}?</p>
            ) : (
              <p>{company?.attributes?.status === 'Active'? 'Disable' : 'Enable'} {company?.attributes?.name}?</p>
            )
          }
        </DialogTitle>
        <DialogContent>
          <CompanyDelete
          setOpenAlertOK={setOpenAlertOK}
          setSuccessMessage={setSuccessMessage}
            companyID={companyID}
            companies={companies_}
            setCompanies={setCompanies}
            handleClose={handleClose}
            companyUserID={companyUser.id}
            companyUser={companyUser}
            currentID={currentID}
            activeOption={activeOption}
            company={company}
            setCompany ={setCompany}
          />
         
        </DialogContent>
      </Dialog>  

{company_Edit=== true
&&<Dialog
   
   open={openModal}
   onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  maxWidth='md'
> 
  <DialogContent>
    <CreateCompany 
     updater={updater}
     setUpdater={setUpdater}
      companies={companies_}
      setCompanies={setCompanies}
      setCompany_={setCompany_}
      company_ ={company_}
      setCompany_Edit={setCompany_Edit}
      company_Edit={company_Edit}
      currentID={currentID}
      setCurrentID={setCurrentID}       
      handleClose={handleClose}
  
/>
  </DialogContent>
</Dialog> }
  

    </Paper>
  );

}