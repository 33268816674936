import { useContext } from 'react'
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TopBar from "./components/TopBar/top-bar.component";
import HomePage from "./pages/HomePage/home.page";
import PasswordChangePage from "./pages/PasswordChangePage/password-change.page";
import SystemPage from "./pages/SystemPage/system.page";
import PasswordResetPage from "./pages/PasswordResetPage/password-reset.page";
import EmployeerPage from "./pages/EmployeerPage/employeer.page";
import LoginPage from "./pages/LoginPage/login.page";
import Register from "./pages/LoginPage/register.component";
import LoginEmail from "./pages/LoginPage/login-email.component";
import LoginPassword from "./pages/LoginPage/login-password.component";
import LoginCreatePassword from "./pages/LoginPage/login-create-password.component";
import Prospect from "./pages/LoginPage/prospect.component";
import DimensionPage from "./pages/DimensionPage/dimension.page";
import IndicatorPage from "./pages/IndicatorPage/indicator.page";
import QuestionPage from "./pages/QuestionPage/question.page";
import { LoginContextProvider } from "./contexts/login-context";
import { AuthProvider } from "./contexts/auth-context";
import PrivateRoute from "./routes/private.routes";
import PublicRoute from "./routes/public.routes";
import PostRolePage from "./pages/PostRolePage/post-role.page";
import { PostRoleProvider } from "./contexts/postRole-context";
import PeriodPage from "./pages/PeriodPage/period.page";
import CurriculumDetailPage from "./pages/CurriculumDetailPage/detail.page";
import CurriculumListPage from "./pages/CurriculumListPage/list.page";
import QuizPage from "./pages/QuizPage/quiz.page";
import CreateCollaboratorMain from "./pages/Collaborator/Collaborator_Creation_Page/Collaborator_Main_Page"
import CreateCompanyMain from './pages/CompanyPage/Company_Creation_Page/Company_Main_Page';
import AvaliaçõesMain from './pages/Ratings/Rating_/AvaliaçõesMain';
import { LanguageProvider } from "../src/translate/languages/LanguageContext";
import CreateCollaborator from './pages/Collaborator/Collaborator_Creation_Page/create_collaborator';
import TopBar2 from './components/TopBar/top-bar2.component';
import ProfilePage from './pages/ProfilePage/profile.page';
import { UserRoleProvider } from './contexts/userRole-context';
import { SnackbarProvider } from './contexts/snackbar-context';
document.title = "AfrikanCoders";

function App() {


  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <UserRoleProvider>
          <AuthProvider>
            <LoginContextProvider>
              <PostRoleProvider>
                <SnackbarProvider>
                  <CssBaseline />
                  <Router>
                    <Routes>
                      <Route element={<PrivateRoute />}>
                        <Route path="/" element={<TopBar />}>
                          <Route index element={<HomePage />} />
                          <Route path="/collaborator/role" element={<PostRolePage />} />
                          <Route path="/collaborator" element={<CreateCollaboratorMain />} />
                          <Route path="/collaborator/create" element={<CreateCollaborator />} />
                          <Route path="/companies" element={<CreateCompanyMain />} />
                          <Route path="/evaluation" element={<AvaliaçõesMain />} />
                          <Route path="password/change" element={<PasswordChangePage />} />
                          <Route path="password/reset" element={<PasswordResetPage />} />
                          <Route path="system" element={<SystemPage />} />
                          <Route path="period" element={<PeriodPage />} />
                          <Route path="dimension" element={<DimensionPage />} />
                          <Route path="indicator" element={<IndicatorPage />} />
                          <Route path="question" element={<QuestionPage />} />
                          <Route path="quiz" element={<QuizPage />} />
                          <Route path="curriculum" element={<CurriculumListPage />} />
                          <Route path="curriculum/details" element={<CurriculumDetailPage />} />
                          <Route path="profile" element={<ProfilePage />} />
                        </Route>

                      </Route>

                      <Route element={<PublicRoute />}>
                        <Route path="/login" element={<LoginPage />}>
                          <Route index element={<LoginEmail />} />
                          <Route path="password" element={<LoginPassword />} />
                          <Route path="password/create" element={<LoginCreatePassword />} />
                        </Route>
                        <Route path="/register" element={<LoginPage />}>
                          <Route index element={<Register />} />
                        </Route>
                        <Route path="/prospect" element={<LoginPage />}>
                          <Route index element={<Prospect />} />
                        </Route>


                      </Route>
                    </Routes>
                  </Router>
                </SnackbarProvider>
              </PostRoleProvider>
            </LoginContextProvider>

          </AuthProvider>
        </UserRoleProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
