import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid, StepIcon, useMediaQuery } from '@mui/material';
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { useContext } from "react";
import i18n from "../../../translate/i18n";
import { theme } from '../../../components/Theme';

const CustomStepIcon = ({ active, completed }) => {
  let iconImage;
  let iconSize = active ? '1.5rem' : '0.8rem';
  let marginLeft = active ? '0.05rem' : '0.35rem';

  if (active) {
    iconImage = '/africanStepperOrange.svg';
  }
   else if (completed) {
    iconImage = '/africanStepperOrange.svg';
  } else {
    iconImage = '/africanStepperGray.svg';
  }

  return (
    <div style={{ position: 'relative' }}>
      <img src={iconImage} alt="Step Icon" style={{ width: iconSize, height: iconSize, marginLeft: marginLeft }} />
      <StepIcon icon={null} /> {/* Hide the default icon */}
    </div>
  );
};



export default function VerticalLinearStepper({
  step, 
  indicators,
  handleStep, 
}) { 
 
  const { selectedLanguage } = useContext(LanguageContext); 
  
  const indicateTypes = indicators.filter((ind)=> 
  ind?.attributes?.evaluation_question_opens?.data.length !== 0)
  const indicateTypes_ = indicators.filter((ind)=> 
  ind?.attributes?.evaluation_question_closeds?.data.length !== 0)

  const indQAMerge = indicateTypes_.map((tx)=> tx)
  const steps = indicateTypes.map((tx)=> tx).concat(indQAMerge)
 const [activeStep, setActiveStep] = React.useState(step);

  React.useEffect(() => {   
    setActiveStep(step);
   // handleStep(step) 
  }, [step ]);
   
 
const isMobileMode = useMediaQuery(theme.breakpoints.up('sm'));
 const indicatorIdx = steps.map((tx)=> tx.attributes.indicator)
 const duplicatSteps =steps.filter((item, index) => indicatorIdx.indexOf(item.attributes.indicator) === index)

  return (
    <Box sx={{ maxWidth: 400 }}> 
      <Grid item container>
        <Grid item style={{ marginBottom: '1rem', marginTop: '1.4rem', marginLeft: '2rem' }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {duplicatSteps?.map((step, index) => (
              <Step key={step.id + index} onClick={() => handleStep(index)}>
                <StepLabel
                  StepIconComponent={CustomStepIcon}>
                  { step.attributes.indicator}
                </StepLabel>
               
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      
    </Box>
  );
}
