import { gql } from "@apollo/client";

export const GET_EVALUATION_PERIODS = gql`
query EvaluationPeriod {
    evaluationPeriods(pagination: { pageSize: 1000 }) {
      data {
        id
        attributes {
          description
          start
          end
          company {
            data {
              id              
              attributes { name branchOfActivity email phone status}
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
                email
                phone
                postRole{data{id attributes{postRole description}}}
              }
            }
          }
          isActive
        }
      }
    }
  }
  
`;
export const GET_PAGINATED_EVALUATION_PERIODS = gql`
query EvaluationPeriod($page:Int!,$pageSize:Int!) {
  evaluationPeriods(pagination:{page:$page,pageSize:$pageSize}) {
    meta {
      pagination {
        page
        pageSize
        pageCount
        total
      }
    }
    data {
      id
      attributes {
        description
        start
        end
        company {
          data {
            id
            attributes {
              name
              branchOfActivity
              email
              phone
              status
            }
          }
        }
        collaborators {
          data {
            id
            attributes {
              name
              email
              phone
            }
          }
        }
        isActive
      }
    }
  }
}
  
`;

export const GET_COLLABORATORS = gql` 
query GetCollaborators {
  collaborators(pagination:{pageSize:1000}) {
    data {
      id
      attributes {
        name
        email
        phone
        web
        intro
        address
        evaluation_scores{data{id attributes{evaluation_answer_closeds{data{id attributes{rate}}} company{data{id attributes{name}}}}}}
        postRole{data{id attributes{postRole description}}}
        city{data{id attributes{name}}}
        country{data{id attributes{name}}}
        image{data{id attributes{url}}}
       profiles(pagination:{pageSize:20}){data{id attributes{urlProfile description platform{data{id attributes{name icon{data{id attributes{url}}}}}}}}} 
        companies{data{id attributes{name}}}
      }
    }
  }
}
`;

export const GET_COMPANIES_AND_COLLABORATORS = gql`
query CompaniesCollaborators {
  companies(
    pagination: { pageSize: 1000 }
    filters: { status: { eq: "Active" } }
  ) {
    data {
      id
      attributes {
        name
        status
        collaborators {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`