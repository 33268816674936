import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Pagination } from '@mui/material';
import { useState } from 'react'; import { useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { toast } from 'react-toastify';
import ColaboradorDelete from './Collaborator_delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import CustomPagination from '../../../components/TableComponents/CustomPagination';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));



export default function CollaboratorList({ collaborators, setCollaborators, setColaborador, colaborador, setColaboradorEdit }) {
  const { selectedLanguage } = React.useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedPage, setSortedPage] = useState(collaborators);

  const itemsPerPage = 10;

  const jwt = localStorage.getItem("jwtToken");

  // Calculate the indexes of the first and last items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Extract the items to display on the current page
  const currentItems = collaborators?.slice(firstIndex, lastIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(collaborators?.length / itemsPerPage);

  // Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  /*  useEffect(() => {
     // Set the current page to 1 whenever the evaluation prop changes
     setSortedPage(collaborators)
     setCurrentPage(1);
   }, [collaborators]); */


  const [collaboratorID, setCollaboratorID] = useState(false);
  const [collaboratorStep, setCollaboratorStep] = useState(true);
  const [collaboratorUser, setCollaboradorUser] = useState("");


  const [open, setOpen] = useState(false);
  const [booleanStatus, setBooleanStatus] = useState(false);
  function handleClose() {
    setOpen(false);
  };

  function LongMenu({ collaboratorID, collaboratorData, collaboratorStep }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const booleanStatus = collaboratorData.isActive

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenView = () => {
      //console.log(collaboratorID);
      setColaborador(collaboratorData);
      handleClose();
    };

    const handleEdit = () => {
      setColaborador(collaboratorData);
      setColaboradorEdit(true);
      handleClose();
    };

    const handleClickDelete = (step) => {
      //console.log(collaboratorID);
      setCollaboratorID(collaboratorID);
      setCollaboradorUser(collaboratorData);
      setCollaboratorStep(step);
      setOpen(true);
      handleClose();
    };
    const handleClickDisable = (step) => {
      //console.log(collaboratorID);
      setCollaboratorID(collaboratorID);
      setCollaboradorUser(collaboratorData);
      setCollaboratorStep(step);
      setOpen(true);
      handleClose();
      setBooleanStatus(collaboratorData.isActive)
    };

    return (
      <>
        <IconButton aria-label="more" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleOpenView}>
            <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
          </MenuItem>
          <MenuItem onClick={handleEdit}>
            <EditOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.edit', { lng: selectedLanguage })}
          </MenuItem>
          <MenuItem onClick={() => handleClickDisable(true)}>

            {booleanStatus ? < ClearOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> : <CheckIcon color='primary' style={{ marginRight: '5px' }} />}
            {booleanStatus ? i18n.t('options.disable', { lng: selectedLanguage }) : i18n.t('options.enable', { lng: selectedLanguage })}
          </MenuItem>
          <MenuItem onClick={() => handleClickDelete(false)}>
            <DeleteIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.delete', { lng: selectedLanguage })}
          </MenuItem>
        </Menu>
      </>
    );
  }


  return (
    <form>
      <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{i18n.t('table.name', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell>{i18n.t('table.location', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell>{i18n.t('table.email', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell>{i18n.t('table.phone', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell>{i18n.t('table.company', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell>{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
                <StyledTableCell>{i18n.t('table.action', { lng: selectedLanguage })}</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentItems?.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row?.name?.split(" ")[0] || "N/A"}
                    {` ${row?.name && row?.name?.split(" ").pop()}` || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.city || "N/A"}<br/>{row.country || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.email || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>{row.phone || "N/A"}</StyledTableCell>
                  <StyledTableCell>
                    {row.companies?.map((historyRow, index) => (
                      <div key={historyRow.id} style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {historyRow?.attributes.name || 'N/A'}
                        </span>
                      </div>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <LongMenu collaboratorID={row.id} collaboratorData={row} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          {
            collaboratorStep === false ? (
              <p>Delete {collaboratorUser.name}?</p>
            ) : (
              <p>{booleanStatus ? 'Disable' : 'Enable'} {collaboratorUser.name}?</p>
            )
          }
        </DialogTitle>
        <DialogContent>
          <ColaboradorDelete
            collaboratorID={collaboratorID}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            collaboratorUserID={collaboratorUser.userID}
            collaboratorUser={collaboratorUser}
            handleClose={handleClose}
            collaboratorStep={collaboratorStep}
          />
        </DialogContent>
      </Dialog>
    </form>
  );
}
