import { useMutation } from '@apollo/client';
import {useState } from 'react';  
 import { DELETE_COMPANY, UPDATE_COMPANY, DISABLE_USERS, DELETE_USERS } from './mutation.gql';
import { Button, Grid } from '@mui/material';

const CompanyDelete = ({company,setSuccessMessage, setOpenAlertOK,activeOption, companyUserID, companyID ,handleClose ,companies,setCompanies}) => {
    console.log(activeOption?"DisableCompanyID" + ' ' +  companyID:"DeleteCompanyID" + ' ' + companyID )
     
    
    const [deleteCompany] = useMutation(DELETE_COMPANY)
    const [disableCompany] = useMutation(UPDATE_COMPANY)
    const [disableUser] = useMutation(DISABLE_USERS)
       const [deleteUser] = useMutation(DELETE_USERS)
  const deleteCompanyItem =  activeOption===false
    const disableCompanyItem = activeOption=== true
    const jwt = localStorage.getItem("jwtToken")
    const [status, setStatus] = useState(company.attributes.status)
   
  
    const handleDisable = async () => {

if(status=== 'Active'){
   try {
            await disableCompany({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: companyID,
                    data: {
                        status: 'Inactive',
                    },
                },
            }).then((data) => { 
           
            const updatedData = companies.filter(obj => obj);
            setCompanies(updatedData)
               setOpenAlertOK(true)
                setSuccessMessage("Empresa atualizado");
                setStatus(company.attributes.status = 'Inactive') 
            });
     
            handleClose()
        } catch (error) {
            console.log(error);
            setOpenAlertOK(false)
            setSuccessMessage("Error");
        } 
       
}else{

   try {
            await disableCompany({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: companyID,
                    data: {
                        status: 'Active',
                    },
                },
            }).then((data) => {               
                setStatus(company.attributes.status = 'Active')
               const updatedData = companies.filter(obj => obj);
            setCompanies(updatedData) 
               setOpenAlertOK(true)
            setSuccessMessage("Empresa atualizado");    
            });
          
            handleClose();
            
        } catch (error) {
            console.log(error);
            setOpenAlertOK(false)
            setSuccessMessage("Error");
        } 
      
}

  };
   

    
    const handleDelete =async () => {  
         handleClose()
        deleteCompany({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
            variables: {
                id: companyID
            },
        }).then((data) => {
       const updatedData = companies.filter(obj => obj.id !== companyID);
            setCompanies(updatedData)
          
            setOpenAlertOK(true)
            setSuccessMessage("Empresa Removido"); 
        })
        .catch((error) => {
            console.log(error)
            setOpenAlertOK(false)
            setSuccessMessage("Error");
        });
     
        
        await deleteUser({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
            variables: {
                id: companyUserID,
                   
            }
        }).catch((error) => {
            console.log(error)
        
        });
           
       
    } 
 
    return (<>
        <form onSubmit={deleteCompanyItem?handleDelete:handleDisable}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        {deleteCompanyItem && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDelete
                            }
                        >Delete</Button>}
                        {disableCompanyItem && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDisable
                            }
                        >{status === 'Active'? 'Disable' : 'Enable'}</Button>}
                    </Grid>
                </Grid> 
            </Grid> 
        </form> 
        
        
        </>
    );

};

export default CompanyDelete;
