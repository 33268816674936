import { AppBar,  SwipeableDrawer, Box, Button, Toolbar, Typography } from "@mui/material";
import React,{ createContext, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../contexts/auth-context";
import CompanyQuestion from "./company-questions.component";
import { Navigate } from "react-router-dom";
import SideBar from "./side-bar.component";
import UserProfileCard from "../../pages/ProfileCard/user_profile_card";
import i18n from "../../translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import { UserRoleContext } from "../../contexts/userRole-context";
import { theme } from '../Theme';
import useMediaQuery from '@mui/material/useMediaQuery';
const TopBar = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const { user, logout } = useContext(AuthContext);
  const { uRoleContext, setURoleContext } = useContext(UserRoleContext);
  const currentLocation = useLocation();
  const isMobileMode = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumMode = useMediaQuery(theme.breakpoints.down('md'));
  const menuItems = [
    { pathname: "/question", message: i18n.t('tittles.questao', { lng: selectedLanguage }) },
    { pathname: "/quiz", message: i18n.t('tittles.quiz', { lng: selectedLanguage }) },
    { pathname: "/collaborator/post-role", message: i18n.t('tittles.role', { lng: selectedLanguage }) },
    { pathname: "/dimension", message: i18n.t('tittles.dimension', { lng: selectedLanguage }) },
    { pathname: "/period", message: i18n.t('tittles.priod', { lng: selectedLanguage }) },
    { pathname: "/collaborator", message: i18n.t('tittles.collaborator', { lng: selectedLanguage }) },
    { pathname: "/indicator", message: i18n.t('tittles.indicator', { lng: selectedLanguage }) },
    { pathname: "/companies", message: i18n.t('tittles.company', { lng: selectedLanguage }) },
    { pathname: "/evaluation", message: i18n.t('tittles.evaluation', { lng: selectedLanguage }) },
    { pathname: "/", message: i18n.t('tittles.dashboard', { lng: selectedLanguage }) }, // Adding the "Dashboard" item
  ];

  const menuItem = menuItems.find((item) => item.pathname === currentLocation.pathname);

  const displayMessage = menuItem ? menuItem.message : i18n.t('tittles.dashboard', { lng: selectedLanguage });

  const styles = {
    toolbarMargin: {
      ...theme.mixins.toolbar,
      marginBottom: '3em',
      [theme.breakpoints.down('md')]: {
        marginBottom: '2em',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1.25em',
      },
    },
    logo: {
      height: '8em',
      [theme.breakpoints.down('md')]: {
        height: '7em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '5.5em',
      },
    },
    logoContainer: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    tabs: {
      marginLeft: 'auto',
      '& .MuiButtonBase-root.MuiTab-root': {
        fontSize: 20,
      },
      '& .Mui-selected': {
        backgroundColor: '#fce0a2',
        color: '#000',
        opacity: 0.7,
        borderRadius: 2,
      },
    },
    tab: {
      ...theme.typography.tab,
      minWidth: 10,
      marginLeft: '25px',
      color: 'white',
    },
  
    hamburgerMenuIcon: {
      height: '50px',
      width: '50px',
    },
    menuIconContainer: {
      marginLeft: 'auto',
      color: 'black',
      '&:hover': {
        opacity: 1,
      },
    },
    appbar: {
      zIndex: theme.zIndex.modal + 1,
    },
  };
  


  const MobileNavigation = () => {
    const [openDrawer, setOpenDrawer] =  useState(false);
  
    const iOS =
      typeof navigator !== 'undefined' &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);
  
    return (
      <React.Fragment>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <Box sx={styles.toolbarMargin} />
          
               <SideBar />
          
      
        </SwipeableDrawer>
        <IconButton
          sx={styles.menuIconContainer}
          onClick={() => setOpenDrawer(!openDrawer)}
          disableRipple
        >
          <MenuIcon sx={styles.hamburgerMenuIcon} />
        </IconButton>
      </React.Fragment>
    );
  };
  


  if (user.id)
    return (

      <>
        <Box sx={{ flexGrow: 1, display: "flex", marginLeft: '2.5rem' }}>
          <AppBar sx={{ bgcolor: "background.paper" ,position:isMobileMode?'absolute':'static' , left:1 ,minWidth:isMobileMode?'145vw':'96.3vw'}}>
           
            <Toolbar disableGutters={true}>
{isMobileMode ? <MobileNavigation /> : <SideBar />}
       <Typography
                color="black"
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, marginLeft:isMobileMode?0: "200px" }}
              >
                 {displayMessage}
               </Typography>
               <UserProfileCard /> 
        </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            backgroundColor: "transparent",
            marginLeft: { xs: "20px", md: "70px" },
            marginRight: { xs: "20px", md: "70px" },
            marginTop: { xs:isMobileMode?"120px": "30px", md: "70px" },
            marginBottom: "20px",
            marginLeft:isMobileMode? 3:`${270}px`,
            minWidth:isMobileMode?'125vw':isMediumMode?'30vw':300
          }}
        >
          <CompanyQuestion user={user} logout={logout}>
            <Outlet />
          </CompanyQuestion>
        </Box>
      </>
    );
  else {
    window.location.href = "/";
  }
};

export default TopBar;
