import { Button, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import CollaboratorList from "./Collaborator_list";
import { GET_COLLABORATORS } from "./query.gql";
import { ToastContainer } from "react-toastify";
import CollaboratorView from "../Collaborator_View_Page/collaborator_detail_main";
import SearchAddBar from "../../../components/TopBar/search-add-bar.component";
import Pathway from "../../../components/TopBar/pathway";
import LoadingCircle from "../../../components/Loading/loading_circle";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import i18n from "../../../translate/i18n";

const CreateCollaboratorMain = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const jwt = localStorage.getItem("jwtToken");
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS)
  const [loading, setLoading] = useState(true);
  const [collaborators, setCollaborators] = useState([])
  const [colaborador, setColaborador] = useState(null);
  const [colaboradorEdit, setColaboradorEdit] = useState(false);
  const [sortedCollaborators, setSortedCollaborators] = useState("")
  const [filteredList, setFilteredList] = useState('');
  const handleReturn = () => {
    setColaborador(null)
    setColaboradorEdit(false)
  };
  const listCollaborator = colaborador === null
  const returnButton = !listCollaborator && (<Button color="primary" onClick={handleReturn}>Return</Button>);
  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {
        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          companies: item.attributes?.companies?.data/* ?.map((company) => {
            return {
              companyID: company.id,
              company: company.attributes?.name,
            };
          }) */,
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,
          score: item.attributes?.evaluation_scores?.data?.map((score) => {
            return {
              id: score.id,
              //rateValue: score.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate),
              rateLength: score.attributes?.evaluation_answer_closeds?.data.length,
              rateTotal: score.attributes?.evaluation_answer_closeds?.data.reduce((accumulator, currentScore) => accumulator + currentScore.attributes.rate, 0),
              company: score.attributes?.company?.data?.attributes?.name,
              companyID: score.attributes?.company?.data?.id

            };
          }),
          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            const matchingScores = item.attributes?.evaluation_scores?.data?.filter((score) => score.attributes?.company?.data?.id === client.id);
            const rateValue = matchingScores.flatMap((matchingScore) =>
              matchingScore?.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate)
            );
            const rateLength = rateValue.length;
            const rateTotal = rateValue.reduce((accumulator, currentScore) => accumulator + currentScore, 0);

            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
              rateTotal: rateTotal,
              rateLength: rateLength,
              rateValue: rateValue,
            };
          }),


          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });
      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setCollaborators(sortedData);
      setLoading(false);
    });
  }, []);

  const filteredSearched = collaborators?.filter(item =>
    Object.values(item).some(value =>
      value?.toString().toLowerCase().includes(filteredList.toLowerCase())
    )
  );
  
  
  if (loading) return <LoadingCircle message={i18n.t('loading.loading', { lng: selectedLanguage })} />
  return (
    <Grid container >
      <ToastContainer />
      <Grid item xs={12}>
      <Pathway/>
        {listCollaborator && <SearchAddBar searched={filteredList}
          setSearched={setFilteredList}
          setData={setCollaborators} />}

        {returnButton}
        {listCollaborator ? (
          <CollaboratorList
            collaborators={filteredSearched}
            setCollaborators={setCollaborators}
            colaborador={colaborador}
            setColaborador={setColaborador}
            setColaboradorEdit={setColaboradorEdit}
          />
        ) : (
          <CollaboratorView
            colaborador={colaborador}
            setColaborador={setColaborador}
            setColaboradorEdit={setColaboradorEdit}
            colaboradorEdit={colaboradorEdit}
            setCollaborators={setCollaborators}
            collaborators={collaborators}
          />
        )}

        {returnButton}
      </Grid>
    </Grid>
  );
}

export default CreateCollaboratorMain;